import logo from './images/logo.png';
import whatsapp from './svg/whatsapp.svg';
import mail from './svg/mail.svg';
import facebook from './svg/facebook.svg';
import instagram from './svg/instagram.svg';
import tiktok from './svg/tik-tok.svg';
import behance from './svg/behance.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <p>
          <img src={logo} className="App-logo" alt="logo" />
        </p>
        <div className='note'>
          <div>
            <p><span className='text-primary'>"</span>NINGÚN SUEÑO ES DEMASIADO</p>
            <p><span className="text-primary">GRANDE</span> Y NINGUN <span className="text-primary">SOÑADOR</span></p>
            <p>ES DEMASIADO PEQUEÑO<span className='text-primary'>"</span>.</p>
          </div>
          <div className='contact'>
            <p><span className="text-primary">-</span>CONTÁCTAME, <span className="bg-primary">HABLEMOS DE DISEÑO</span></p>
          </div>
        </div>

        

        <div className='social'>
          <div className="top">
          
            <a
              className="App-link"
              href="https://bit.ly/47V6tTu"
              target="_blank"
            >
              <img src={whatsapp} className="App-social" alt="logo" />
              <span> <span className="text-primary">+591 </span> 707 715 79</span>
            </a>
            
          
            <a
              className="App-link"
              href="mailto:info@jhurgen.com"
              target="_blank"
            >
              <img src={mail} className="App-social" alt="logo" />
              <span> info@<span className="text-primary">jhurgen</span>.com</span>
            </a>
          
          </div>
          <div className='foot'>
            <a
            className="App-link"
            href="https://www.facebook.com/jhurgen.lsj"
            target="_blank"
          >
            <img src={facebook} className="App-social" alt="logo" />
          </a>
          <a
            className="App-link"
            href="https://www.instagram.com/jhurgen.sj"
            target="_blank"
          >
            <img src={instagram} className="App-social" alt="logo" />
          </a>
          <a
            className="App-link"
            href="https://www.tiktok.com/@jhurgen.sj"
            target="_blank"
          >
            <img src={tiktok} className="App-social" alt="logo" />
          </a>
          <a
            className="App-link"
            href="https://www.behance.net/jhurgen"
            target="_blank"
          >
            <img src={behance} className="App-social" alt="logo" />
          </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
